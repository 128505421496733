
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexTTRNQdF7TcdigsSvlNjnGjtIx2PnJx8_NsXS4Nc48HMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/alteracao-de-senha/index.vue?macro=true";
import { default as index_2vjtHm_JgxzmhHURAFsPeK_45eGdrbGYum1Wy6MBE0pAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/cadastro/index.vue?macro=true";
import { default as _91uuid_937gl8zDFQ_45aR6XeE38ccfGxPp9d3AVbe8V5XLQ3L1p5cMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/confirmacao-de-email/[uuid].vue?macro=true";
import { default as _91id_93BP6v07Pg4HvbCUkyMsISJkTKhAKB5vgWItjDU2m7mbwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/configurar-transporte/[id].vue?macro=true";
import { default as _91id_93sXRA6ymxmcrgMRAcYm2FRCA5N4fCuo8AEtrpzHwoidEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/editar-venda/[id].vue?macro=true";
import { default as _91slug_937TOFB1R_JDj3quOCJRBFh81a_45_45OBFOFO0wWlhfAP_45XsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/em-transito/[slug].vue?macro=true";
import { default as indexiDo_94XWUbT0VnLz7QtE2IaW2LYnknRxhk0fohsRB10Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/[uuid]/index.vue?macro=true";
import { default as indexkmn6vKTLZQCR0XOR8tVH58yOCk1EdcVBpok9euJ_45m8MMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/index.vue?macro=true";
import { default as indexFDRusMYj_45yvxdfQQzbkJGbyfvjpETKobS4iQ8K8FQVoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/novo/index.vue?macro=true";
import { default as indexPdHrs0ongK4PscVuaBAgOooqFRAnTMANbGQkoOzkZn8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/[uuid]/index.vue?macro=true";
import { default as index5wrX7xeoO3NZlsEWEWV4h1aL_yS1Fya914rr9m5SazYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/index.vue?macro=true";
import { default as indexBX6EkY9FOAPodh7hj5rX8_OQMt0J7m3XoB3ZWScErKoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/novo/index.vue?macro=true";
import { default as indexgmd0pDB46FgtbKL87e6IIwH8nYOOomxXOPe2CYIqOz8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/index.vue?macro=true";
import { default as indexw0NgPMCEB_H4KBYBOeeR3lyDKtv492UHnARiqSLSNEIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/novo/index.vue?macro=true";
import { default as indexnTC4qSJUITfi6_45JRYfEWjVPfbUW5FZpvHEPwBv_45qgtsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/[uuid]/index.vue?macro=true";
import { default as indexy_45U1cNFfsa18iVuWzoEeothULJmtSDb9FU2oZQqFZocMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/index.vue?macro=true";
import { default as indexMdj15apFf93bXViZ9ewC4vCLvC2Pq2x1_4546xOKm9ZvcMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/novo/index.vue?macro=true";
import { default as indexdDEsvw8Qyczr_45OgW2rn_CGu0xH2fAgfehd12yFhW2hgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/comissao-de-promotores/[id]/index.vue?macro=true";
import { default as index7hreVGaBwe05P6NeK0Ixl5K1ILmPdZJW8gccFcZ9UmcMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/comissao-de-promotores/index.vue?macro=true";
import { default as indexGGL0kCpvB8UYGuN8QbUdcbYRBoPWDNjjf_PGptL0ta4Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/[uuid]/index.vue?macro=true";
import { default as indexNQuUA48IWVr5j_45n7xE5D1Cc5CWSU1fig7_45o9phXJJCQMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/index.vue?macro=true";
import { default as indexu05n2el5WThP43xGGKnfdUh6KSzS0rdyRrJB2HfSBAEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/novo/index.vue?macro=true";
import { default as index8AuD56j_45r5KiOUzMXccH0k2_5C_oHlDtHBpJY8HjSuoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/[uuid]/index.vue?macro=true";
import { default as indexxKO5_45XaIbvI3EGjlGFOB6XaMpIuQ7PEgLUdoz6kR3ukMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/index.vue?macro=true";
import { default as index7WSKxClVB127PZEIJfX4wPnSdUvZXaWmAruNRrfD2DgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/novo/index.vue?macro=true";
import { default as indexWRRKBE5CZX6_45GJzsv_O623d_45x_PTE_45B3_45qkswH4RtxsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/importar-mailing/index.vue?macro=true";
import { default as indexrJHXcnsY91rvqaKcQRXVXlMwKNFWp_2BrggU3xMOkUMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/index.vue?macro=true";
import { default as indexBP7rdqb1rGjrgfZfNcN6BiHi7trS8Eb5nw2kV9DfCQMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/[id]/index.vue?macro=true";
import { default as indexO6EpIk3KX_45rnypZn9ikNPTSKfBQ9ILN36ksMKoiltz0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/index.vue?macro=true";
import { default as FormLPRegistrationRZ92ePXrbzqnvhlrJ3SWG13KT9RI88AT7VnZiP9zTkUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/novo/FormLPRegistration.vue?macro=true";
import { default as indexFVss91490Pk_4534RdT3nXjAoZfItrukJITmsZtk6tJBAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/novo/index.vue?macro=true";
import { default as indexst7HaojFGiTG_AKN97MXaEPLS47MxQGrxzJsHmEYxdcMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/[id]/index.vue?macro=true";
import { default as indexP9AeWlHnWT3REwV7uKVyCwFvw1GLAfj9cdU6rZ9rSE0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/index.vue?macro=true";
import { default as indexTYmpG_45C25aJgALtl88sUr0GqUxKor1khVsY_458FtdsTwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/novo/index.vue?macro=true";
import { default as indexPt5foG5uuSxsyvJFuL7I4eOkrZG18c5R4HenvPDdUBMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/[uuid]/index.vue?macro=true";
import { default as indexUdMTTEUuGavpln3Y3pJ2EJuMp3ku8NL7BmjuAOR4PQUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/index.vue?macro=true";
import { default as index_aXHts6jaV238HSuAHQJcp_45Djyzc5XTTi0DkNADzvNsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/novo/index.vue?macro=true";
import { default as indexDxJa5PB71qZM9heBYsXeOkmL1G08nMkGkpyp9_4HLBYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/[id]/index.vue?macro=true";
import { default as indexfIm6ruLb140QmKiNA9B0u8ajm9_45VsV_IYevEHfvUaasMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/index.vue?macro=true";
import { default as FormPlansLPRegistrationW_45pqyeOKeYq8q0RPDw7pI_5dHq1jpWzfuPC58jU2CVAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/novo/FormPlansLPRegistration.vue?macro=true";
import { default as indexdpDdqdsnsAaw7x0cLj_dr12yCaekDbm7_45nlvFCZi72kMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/novo/index.vue?macro=true";
import { default as indexVqE30VpAbWOZnIjNAIBp2l9ZrL0nq6f3AzhkAg7PzKYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/[uuid]/index.vue?macro=true";
import { default as index0AMCToDCDaKUygR4TXSlRxgsIBqxit72EXzB_XOeIocMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/index.vue?macro=true";
import { default as index1Fl0_45bXNLeRljIOKaXDY_45J3dPjRYs_45qSR36zSAfoXx0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/novo/index.vue?macro=true";
import { default as indexsJWHpDghXbvOzsiKEXNqTLIqTXIBJeJHuk_45AMQ1GL_gMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/[id]/index.vue?macro=true";
import { default as indexFf7_anhtTvRDvK78v7p3lGvhpi_fgRCVv0fKuCVjcDUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/index.vue?macro=true";
import { default as indexzq_45w1bm_vfFfp8moYo10JGZqT0hjSYwW6kMulAwmhHkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/novo/index.vue?macro=true";
import { default as index_B_45tciZEES7ELBUN2s24BcWq_45WWIE9OMzYA65Dx8_45AAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/[id]/index.vue?macro=true";
import { default as indexFdgrA2I2lP9W6HstV1DzwXjtxVsM6Ipsneeh2fnxIvEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/index.vue?macro=true";
import { default as indexhVthg_45dG7JT4WyKYW_akili0jqbccFspWUNvA7cuqlsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/novo/index.vue?macro=true";
import { default as indexmlr5lINkBTGQ_RRmedNsJ49G9904QtaH_rycbdyPn0wMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/[uuid]/index.vue?macro=true";
import { default as index76rTznV25m0oARh2tHXuFcZtBZ_45sCbUMeQFHU06nS8gMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/index.vue?macro=true";
import { default as index7BCyYu5RB8Eyc9Xx1SgCK2THHfuw4Bf4Bf1bFIxkfegMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/novo/index.vue?macro=true";
import { default as indexWfidEZc1DH3aRx4UilRsTM8xDbq7mRzWUiK0ecHQKAIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/[uuid]/index.vue?macro=true";
import { default as indexLE0A3pojXqvmdQt3HtR0ug0BJkiEfSccRySE36ThIvYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/index.vue?macro=true";
import { default as indexLVkEuv8l6IiFkG2H33K_MLhW5i9MIdC1_45Gf9T8kh10sMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/novo/index.vue?macro=true";
import { default as indexqJhQg7sx1G8ulz6LPK_45w2lnv6xsXB66xS6mt5mTLLdwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/base-envios/contatos/index.vue?macro=true";
import { default as indexWQ0e3_45SQB9jH9LfudT1od2_YlE7hHFq3qVFdn_45kwPbYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/base-envios/index.vue?macro=true";
import { default as indexfQJJp1lz1awOVi2arvDQXiqzHhtnczrUYuG8Yhiv774Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/base-envios/novo/index.vue?macro=true";
import { default as indexbBIcPAx9ZMBxP_YD4I_2Qy8y_g25CDnGmX1epruM9ggMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/campanha-individual/index.vue?macro=true";
import { default as CardViewpSthD_XBB7BSBVTPRSUC0a69zcOF6KKVVskaQ4OsFwkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/CardView.vue?macro=true";
import { default as indexU3epqYyPhe2H_45iZZyNr0665EV3UYuP6sZjAV1RwMx9gMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/index.vue?macro=true";
import { default as indexqDv4vhdjfG7ruhsKuYff_45NQRHL7A_45qB5ogrnBwWILnAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/template-email/index.vue?macro=true";
import { default as index8DusR7piJfbdKIrkEhVKhvA37dB7I_Gk4_45nzJq7mXEgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/template-sms/index.vue?macro=true";
import { default as indexrGZGYxzj1OVOUBdAt3Bid_45g4Y7A5uyePLNBiWr326z8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/template-whatsapp/index.vue?macro=true";
import { default as indexnfcFAUX2L9NeevHWzhs7EEzz8cT1iYJz9e_45xoxWEGaQMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/detalhes-da-campanha/[id]/index.vue?macro=true";
import { default as index5_BhQIkAPSfrIeivu2gTVG1KTcNgM3pmu77AIYB3S3YMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/enviar-campanha/index.vue?macro=true";
import { default as FilterF9lCXDA9IiGwfccaxDlU6uou1ss0_MrH_45Rc1IhDSA0YMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/Filter.vue?macro=true";
import { default as indexZpFlGX0IZARfN2EaMYFqMv_xI7aDVLtW8n2slSxZ3QoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/historico-envios/index.vue?macro=true";
import { default as indexYziHxWMAsJMW9D_tu2b_45XB_H17zCvKcDqrH4cbz_45w70Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/index.vue?macro=true";
import { default as ListView6kfO6dDozaur0vUSEdsHSQsazFHQRv39SM9LLC3rMikMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/ListView.vue?macro=true";
import { default as index_4576ial1bJQTm41g_zCQ_45fika5XHf_45ZknbpTgasrwRfwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/meus-compartilhamentos/index.vue?macro=true";
import { default as indexRVCySTDYw0luQX62bNOHF1KogCjmcGLNX5VTEVaoYxYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/ajuda-login/index.vue?macro=true";
import { default as indexh2xmbbJMHTNZAA_aUT_ugFiNNbzKrMbYCjX0Gm5uVusMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/faq/index.vue?macro=true";
import { default as index3wVVMhEjbBN5frAXocBxBx224FbF9nzYT0ePgbELnZcMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/index.vue?macro=true";
import { default as indexTYTIjsZOeti0bv_4561y9_V6YDjs55gsnP4tP5KWLUztIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/pagamentos/index.vue?macro=true";
import { default as indexLgT2x9t029XN44GanFqJYzptmE81wHyjr5NtTIE5EC0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/tutorial-uso-vemqda/index.vue?macro=true";
import { default as indexA8xXzYunPqBerP7sE4tgO1pMC7ucr_45pw4FxgANF5uk8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/chat/index.vue?macro=true";
import { default as indexvP0SlGeATa1LGuu7U3n30jX5RnXiWgcpy_gxAqcwP_oMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/consultar-produtos/index.vue?macro=true";
import { default as indexRQhIUBSCJijFjWzJx_45IovjmU461Z3_Mh23espUZGWpYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/auditoria-de-venda/index.vue?macro=true";
import { default as index303IZ6BytFHgu9XfsXN0aCvnJkrhZVKw95iE2OwfhXAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/index.vue?macro=true";
import { default as seleciona_45provedorUF_45msDgBXAqfDEZhp5vLgj25yXr3QaMznRRsMytlf3UMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/seleciona-provedor.vue?macro=true";
import { default as index8dK9X274_JkINQ7Jm_jQy0bigJlmKhp_453_45ZQ4nZImNoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/historico-de-vendas/index.vue?macro=true";
import { default as indexLFZV8JGlpqCS5T1tdpriuahmjzNOiz_Dzb5EglXr_YkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/index.vue?macro=true";
import { default as indexhpNaJaREEOnxKyh7F7M9eBLND4D_9yZbjRkMkyONr2gMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/leads-disparo/index.vue?macro=true";
import { default as indexsB1QX1Dvm_45cs53XcywYcQEKnvgU3AdOIja1StpOqiIsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/leads/index.vue?macro=true";
import { default as indexlRtmsvD1PoJo00vKE_45_sJeQv1UF_452o3xf6fiK10bcS0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/meu-extrato/index.vue?macro=true";
import { default as CardViewC2Md9Nk38_JHgnF6y2blvjJCFEu_LL5Tm_Z78UTzHqYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/CardView.vue?macro=true";
import { default as indexuqld5EVBIoFYPWSPU2Ntw52tCWZHoVjBFHYWs9Bvur4Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/detalhes-da-oferta/[id]/index.vue?macro=true";
import { default as Filter9GgoktRr1JdWoFdKeIzFfaqR74oyRlODQIoHzxfOTP0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/Filter.vue?macro=true";
import { default as indexQ4zXhdc2YugQ8XVdRsr5RpY_Xj2YdhldJSyJJ40jmQgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/index.vue?macro=true";
import { default as ListView35qcqt8l6HlAgt1QOqwO97tfXvTVCsgAY_45_456b4B2uVkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/ListView.vue?macro=true";
import { default as index09e8qvf8BjTL6SobQgPGG1gwDvyFQgz9gzmk3iC8zi4Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/meus-compartilhamentos/index.vue?macro=true";
import { default as indexeAPOnEQHVI626Zj1a4dqNdiHyaTtkje7qZmINFQQ91oMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/planos/index.vue?macro=true";
import { default as indexWdxYvNuHk_45VCu4Km7P5z_45jpb96uytVKB_45iPGKkOl_454IMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/extrato-de-comissionamento/index.vue?macro=true";
import { default as indexzhZL2UEgdsa_NCYuV1v5MZt9CS2hDmU5duCjhhxssmsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/index.vue?macro=true";
import { default as index9hHsrempfNmy5jYgq5ZxMmXLDcLhZ65e9bn_OUYsUkYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-contingencia/index.vue?macro=true";
import { default as index6E_n8_45m72j6WelOg_45XNbNjr6DtLlV1SB2r2NOv323tYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-credito/index.vue?macro=true";
import { default as indexfV5gJgSRlnwNSTaTLgPxA8Nana0kdL6KD7FO3t79yh0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-historico-de-status-substatus/index.vue?macro=true";
import { default as indexPYXRRhjx_ouk6g_45KoJL2YkBLYwi18R7L9mIdGexG_45mwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-mailings-de-ofertas/index.vue?macro=true";
import { default as index_wfR08k9wORi8auQzjq_45QfvPvOCeHGlS4jnmj_45kahGkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-ultimo-acesso/index.vue?macro=true";
import { default as indexQkzLTlNX_ndCUgcZeq9oAGZxua0ezsKd4vC1_9HqjTgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/suporte/index.vue?macro=true";
import { default as edicao_45de_45bancoWNTzZvO_4518pIVPUrdvYUWDHl5bU_45_459qf79udI5pnQpAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/usuario/edicao-de-banco.vue?macro=true";
import { default as aguardando_45confirmacao_45clientekVp6qw0heV9TowKtqN2lIuTf6SXDh4opEp2DJ4xTd_0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente.vue?macro=true";
import { default as consultadJWWVCDLoGN0X8GZOAlagTZtHd6cC_yhjHAdFSCBDhwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/consulta.vue?macro=true";
import { default as dados_45do_45cliente0W0pgO3aCJhso5QH3eien2yQ1cbk9H62KL_rVLeF02EMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/dados-do-cliente.vue?macro=true";
import { default as informacoes_45do_45planoGDYWTbFbaU6G_45qGlnhUEhjkc25VVpoIA3ZnvHXwoLaQMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/informacoes-do-plano.vue?macro=true";
import { default as retirada_45confirmada8fkazasX2_ZUzHsdRmfmKuQaNZQXXlupQ8STbUj3s4EMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/retirada-confirmada.vue?macro=true";
import { default as indexMMqwVrNMNy0jsX_Kdt86_454SorJHu9EsyJgU5dhcV9PgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/analise-de-credito/index.vue?macro=true";
import { default as indexxjxiC7yVL2SWSpsWm1slb501wAwPN5FAgCwYKlHKAnEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as index1RZAHonGAecYKe2FZJxfuDqtO7Oi6gO2zo0_45TnSkAFoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexRRiZsoPjnBdHKStQg5Vjrw9gUJDjj2E4tun5PjGmodEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexQlIJT4pntMnW2XCTJs9kN9xcYSCiXVuNB70oSuLARckMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexYBEcz1qGKp_ItPkZc0T4Y3fJgfYEePhZFAcXpWfF9ScMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/planos/index.vue?macro=true";
import { default as indexKijjY0J5dAcB_45Vlk5kBVk__45EfZU0Stz1SMO8_7DxN4EMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/edicao-de-venda/[id]/index.vue?macro=true";
import { default as index2ZA4cOOhUGYnBzjEEfJyTO1WoB5QKaTrfKlGbw0k_458MMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-categoria/index.vue?macro=true";
import { default as indexe29kbkvatsiwjNks_45mjLpScO4ntBVQcUDxvrpCVRD3MMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-operadora/index.vue?macro=true";
import { default as indexPFJsr_Bo3QN30pSxoe58E0zjCn424WCDDfOre4vOwKgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-do-tipo-de-venda/index.vue?macro=true";
import { default as indexoLZh_JoDpVs2QChiyIJbJGnh7NqaKzEq59fMWMJutvkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/analise-de-credito/index.vue?macro=true";
import { default as indexteb0zaJnUST59j4F10_gICu6fdbrcji_NK6F2uy2HEMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexI8Cvc72556QS5ug754cowvFf7Qm7U2P_45A4rq__dsRIkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexwZLXcNvT1WycwvEX85S70twhJho2XEYBauX3WdqM5fAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexKs6oWql4AJ3PYArmmnq1SHLFrWe0edMfKqsegR_Y_45SAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexZafzXrnMDZgUOccqLPSA86k7ogF15nV6_45eNbqZsMmXIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/planos/index.vue?macro=true";
import { default as index9Fc4OOVO0xS7I3rj7yyjp4f8g1XAlW35dTtKF6IapPUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/analise-de-credito/index.vue?macro=true";
import { default as indexlyCrUwLCw8qLhb6jH0xTVXSzvW66gxbPt46GO3iHK28Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexCfVIHirzCJgrJ2yOwfQdt_45t1HM9pwYnDtLOw_grem2UMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexalp74jBqNFm2stdv8dr2SWuLAXUceRCvHF9pURjgTvwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexx97wVXfIXrkceC6jgpbisKXweSogrpCOGZ_45dfZyBPdoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexoqdGwCyE3t94JKoCkr_45yrULedpwsD_458a5TXRSlvvIyEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/planos/index.vue?macro=true";
import { default as indexWNXB_ImsUl_45w11uTRde8sP9QOCkq_459zSN_0WLkPShPUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/verificacao-usuario/index.vue?macro=true";
import { default as indexId4PnwR8q20_45th78AkHHJRgzLFz3irQM3Y2spdxJyoIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura/index.vue?macro=true";
import { default as indexMvYqwuq_45nUH4zxAx3Wzth_k4ws2_454OelgWIv_AECfu8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-credito/index.vue?macro=true";
import { default as indexsv3krUwAQvQ6TxAQKUX_HONRX9odjoj9jW1l_459kFTqQMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as index_CufMIP1i6MDCnivMv0ShFX6m7Of3jbgA6WJx7U8JzgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente/index.vue?macro=true";
import { default as index6CgM1PhHfgFuXeUwpgxzi0WBHkGwtPh92p_45FqJqHmeUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexgpChXUlF5F6o6hm6mYdz0N5FmfDVEkFTNRb_45VqoP47sMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/planos/index.vue?macro=true";
import { default as indexxTdUz4Pxr_458_45lPQpQu2ODMSYb6xOewjGmU7MDd6cfPgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warny7wYSqyzaE0yHRDrWFFb9IR9fgOg1n8k8r5jizvTC0cMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationNFBewihza1cQIB4N0LuDaw_45uLoguDy4Z6vXGwiL0p18Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45linecyYEze1FJIXeUrhxAakhAojjS44zbQ0lPwWd9tYUShUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityysaGd60wYRgQ_1B0CB3_dfmiQopVE0tRZ_45X0JwoKD2oMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexupySLd6e9viABX45qf_pOw1UE3BYRyLTQaM30a4GLRoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/analise-de-credito/index.vue?macro=true";
import { default as indexOWfHK_4525VScbGad85xVweBNBwOlNfv89LhTaENNwiEEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexJcncKnLuRV55IIFhE7IWOspuwV1Y9sGAajyRI_HvNeUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexgqwPtod1RZl8XqrbWY0ttUfc4dfJdJHukUXFKoSF610Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexe8I1F_fKEClUwbZZSHFcbZbc3f2KOLfoEdgmxMyvz_UMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/planos/index.vue?macro=true";
import { default as indexKq0x1F3HjAkyg2XfuYIHhThD6jT0q8QjWI7RY7vj_45ZgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warnoIQvC3coLxxkpLX1mYmYneMUYy3qlK_45Qz82xk_45D0bNgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationq8baQ53fccTNJmtvnAtw9_O3IxoGCX0lDtyu3HFWc2AMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45line6lF8g0DQPyhMkfnjCHhNUC56KhGCjERSsQmLYL2gl7MMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityEu7pMzIs7i6kKkz79_45Q8HmVYVJBG2N_45HdMWcibQkwdoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexBHyInApaLUxWrAFsF3PdbCKwPSYHQ_456QsAAgQvxNVosMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito/index.vue?macro=true";
import { default as indexxm0Qz7BGp98N5IlJx4L1PFHNyTpeQqJaH4bQdpBkTU0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexD84JhRsOrxAGpYeBMURoePb_45zS_xWPsQVS6Avd6ipmUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexWwpeqWg0sChRsq4QO6SwQ7H5upqBVkaoFTxL9Wjx3toMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexdt9tZfzyvN5Q4g1rIZsDS9cdy6TQz73V362hg0um48AMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/planos/index.vue?macro=true";
import { default as indexwqIlNKcBgHI4JSYXTPbJYhkwbVj3TJvJJD7r6nkOEUkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warnjpLUTe_ZCpdVbMbRpe5UnyRGTEOGOtZLMB5BoTP3LqMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migration_45NGfHQOt2GTityQdR_37gL1f4kkPbU1Ch_uQEguxmvkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45lineCHUrLbQKuzgBm70C6LVOpmp34C38db60nOg59toT2OEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityjbPTta6EemSdJNwFRYByvOfyjT8MTOw4ICKjWFLo_45wsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexo2N_45lDB3utkImMRjuLcVG9jTRYVu7Ux1n21y9A1md_AMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/analise-de-credito/index.vue?macro=true";
import { default as indexXTsTkyqBvFp4H7pZLlO_45sy4Lt3eoxZIgQbs4t3o62A4Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexrLryfyxGV_j_45q1uUm12vNkXa8lbc5vfnY4WwXLX7dtoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as index2q3Cd_AASfjNWKSbdWZpmD2PU4X82BF2mJWV9jsvBvEMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexYiUvWVKJSEuthX0IirFom9T9fUNddQIZ92eVvaadS7wMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexTRznsnC0n_45_459OuYUlAt7ZMWBByB6KUOEvdrKMAMaea4Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/planos/index.vue?macro=true";
import { default as indexLwzJT8_45RFZ1w2tDE1SbH8gaHxFWteXmWBsSInuKPtHoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/analise-de-credito/index.vue?macro=true";
import { default as indexZ8hIwqkTpSN7I3TPDdqi5_O4QyunN1WlgGh2HrxT_45n0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexq12H7tgSVTy0T9QnHFF3jNTJUeKtqxbx6nody5LZ3hkMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexqn2h12oEo1iWc5ZSHCRUS0nugqH9z_9GfqiPsIQUMV8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexbBEswM97T5Yj21Ac_45kiBTuoainiFZGro4tNmEtzZ4K0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexk24vf4IcjONzx_JgZRJVZG09SBXLeek6xNFPQ4Y3c24Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/planos/index.vue?macro=true";
import { default as indexyD4e2pNTG6cdpzL01efCdeXmb6APzIINULmc7JH6N0MMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/faq/index.vue?macro=true";
import { default as indexCLxqMlGgImA3Wtxa93Tyf_45MkJWlSa32WNm_aPiBCx0MMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/index.vue?macro=true";
import { default as indexERjuIaWyRZS36IJaUpHS0G3WutKQ1WKoTF1woK3tH5sMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/login/index.vue?macro=true";
import { default as index_45tTP8WfkplEoHFRxoZ2SJqgtc_45IPYXWq9k9_45oskO35cMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/recuperacao-de-senha/index.vue?macro=true";
import { default as aceite_45de_45vendafiFuP0wEkyVUaKsHBNCLdG84bhIopBT6P7XBb7tICmwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/alugacell/aceite-de-venda.vue?macro=true";
import { default as indexVItmeGUpdQWvkrfAOV3QycNmJfXCoG3Pz9rXllRvBrYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/aceite-de-venda/index.vue?macro=true";
import { default as indexEdrvrhjZpuWZcwALCt25WJsV6TcdcdLicySCcqBckXgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/venda-aceita/index.vue?macro=true";
import { default as index_jNIQCJdnNgtxmfC_EjqDPmFJj2kiXyZLq6FfJL7IacMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/index.vue?macro=true";
import { default as indexHuuDqvUNKjxZZ2bp8bMLN9OPH_jHutYvJr_2w4hWqAoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/[pdv]/index.vue?macro=true";
import { default as indexi8Tt_45Mh_45Z0TpYr3FUh_ULbn1DduJBQDndoy98aaDw0EMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/index.vue?macro=true";
import { default as indextdOnSEj24zFOqZNq1SPf_451HkhTLuv_rwS_45_mJy3OP7gMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/aceite-de-venda/index.vue?macro=true";
import { default as indexmU0WJGUhLvD_45tcSF_45FakhUEB9IyTJznetgWWLc6OsPYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/venda-aceita/index.vue?macro=true";
import { default as indexwq2V_wvm5OkhBBhPkdoIJoszDxFxprsppaaV5itNGB8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/aceite-de-venda/index.vue?macro=true";
import { default as indexccUYKpBjDyIhjHSGCFNslTbIh245snG8RqA_izsj8wIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/venda-aceita/index.vue?macro=true";
import { default as index96ncbL5dFnkzqm7o06Q1wFnrSa_kcevpohaJ6EEtQ0QMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/[pdv]/index.vue?macro=true";
import { default as indexpS5dUI7Dktj4GD4pPADuv7ncSdGpk6Ctjx6dBC7AoHwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/index.vue?macro=true";
import { default as indexwdMraK_45624_45CrYeh24WJ2gupf27j1iqSERtI63gkB34Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/agendamento/index.vue?macro=true";
import { default as indexkqNko4oDPpYEC7KDaW0CKg1x6aJ0LwsHCYfFBUFeXZoMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-de-venda/index.vue?macro=true";
import { default as indexaurDpRCcDlo0AAbaAESceFqmFAT7sHCVmO4KT2ahFkIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-finalizado/index.vue?macro=true";
import { default as indexEQXYyniIw223hSWHKKJNAMHLMfSwUD67vbSJWZU4n5oMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-recusado/index.vue?macro=true";
import { default as indexu6Y8lfjfMF69rVj8Q9yRAs7tlk0GL4LUam5RDxzJsPwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/venda-aceita/index.vue?macro=true";
export default [
  {
    name: "theme-alteracao-de-senha",
    path: "/:theme()/alteracao-de-senha",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/alteracao-de-senha/index.vue")
  },
  {
    name: "theme-cadastro",
    path: "/:theme()/cadastro",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/cadastro/index.vue")
  },
  {
    name: "theme-confirmacao-de-email-uuid",
    path: "/:theme()/confirmacao-de-email/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/confirmacao-de-email/[uuid].vue")
  },
  {
    name: "theme-dashboard-alugacell-configurar-transporte-id",
    path: "/:theme()/dashboard/alugacell/configurar-transporte/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/configurar-transporte/[id].vue")
  },
  {
    name: "theme-dashboard-alugacell-editar-venda-id",
    path: "/:theme()/dashboard/alugacell/editar-venda/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/editar-venda/[id].vue")
  },
  {
    name: "theme-dashboard-alugacell-em-transito-slug",
    path: "/:theme()/dashboard/alugacell/em-transito/:slug()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/em-transito/[slug].vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell-uuid",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell-novo",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens-uuid",
    path: "/:theme()/dashboard/cadastros/armazens/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens",
    path: "/:theme()/dashboard/cadastros/armazens",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens-novo",
    path: "/:theme()/dashboard/cadastros/armazens/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-campanhas",
    path: "/:theme()/dashboard/cadastros/campanhas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-campanhas-novo",
    path: "/:theme()/dashboard/cadastros/campanhas/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos-uuid",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos-novo",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-comissao-de-promotores-id",
    path: "/:theme()/dashboard/cadastros/comissao-de-promotores/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/comissao-de-promotores/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-comissao-de-promotores",
    path: "/:theme()/dashboard/cadastros/comissao-de-promotores",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/comissao-de-promotores/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos-uuid",
    path: "/:theme()/dashboard/cadastros/dispositivos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos",
    path: "/:theme()/dashboard/cadastros/dispositivos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos-novo",
    path: "/:theme()/dashboard/cadastros/dispositivos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques-uuid",
    path: "/:theme()/dashboard/cadastros/estoques/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques",
    path: "/:theme()/dashboard/cadastros/estoques",
    meta: indexxKO5_45XaIbvI3EGjlGFOB6XaMpIuQ7PEgLUdoz6kR3ukMeta || {},
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques-novo",
    path: "/:theme()/dashboard/cadastros/estoques/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-importar-mailing",
    path: "/:theme()/dashboard/cadastros/importar-mailing",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/importar-mailing/index.vue")
  },
  {
    name: "theme-dashboard-cadastros",
    path: "/:theme()/dashboard/cadastros",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-landing-pages-id",
    path: "/:theme()/dashboard/cadastros/landing-pages/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-landing-pages",
    path: "/:theme()/dashboard/cadastros/landing-pages",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-landing-pages-novo-FormLPRegistration",
    path: "/:theme()/dashboard/cadastros/landing-pages/novo/FormLPRegistration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/novo/FormLPRegistration.vue")
  },
  {
    name: "theme-dashboard-cadastros-landing-pages-novo",
    path: "/:theme()/dashboard/cadastros/landing-pages/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/landing-pages/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas-id",
    path: "/:theme()/dashboard/cadastros/ofertas/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas",
    path: "/:theme()/dashboard/cadastros/ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas-novo",
    path: "/:theme()/dashboard/cadastros/ofertas/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs-uuid",
    path: "/:theme()/dashboard/cadastros/pdvs/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs",
    path: "/:theme()/dashboard/cadastros/pdvs",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs-novo",
    path: "/:theme()/dashboard/cadastros/pdvs/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-landing-pages-id",
    path: "/:theme()/dashboard/cadastros/planos-landing-pages/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-landing-pages",
    path: "/:theme()/dashboard/cadastros/planos-landing-pages",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-landing-pages-novo-FormPlansLPRegistration",
    path: "/:theme()/dashboard/cadastros/planos-landing-pages/novo/FormPlansLPRegistration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/novo/FormPlansLPRegistration.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-landing-pages-novo",
    path: "/:theme()/dashboard/cadastros/planos-landing-pages/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos-landing-pages/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-uuid",
    path: "/:theme()/dashboard/cadastros/planos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos",
    path: "/:theme()/dashboard/cadastros/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-novo",
    path: "/:theme()/dashboard/cadastros/planos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores-id",
    path: "/:theme()/dashboard/cadastros/provedores/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores",
    path: "/:theme()/dashboard/cadastros/provedores",
    meta: indexFf7_anhtTvRDvK78v7p3lGvhpi_fgRCVv0fKuCVjcDUMeta || {},
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores-novo",
    path: "/:theme()/dashboard/cadastros/provedores/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes-id",
    path: "/:theme()/dashboard/cadastros/redes/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes",
    path: "/:theme()/dashboard/cadastros/redes",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes-novo",
    path: "/:theme()/dashboard/cadastros/redes/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos-uuid",
    path: "/:theme()/dashboard/cadastros/servicos-ativos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos",
    path: "/:theme()/dashboard/cadastros/servicos-ativos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos-novo",
    path: "/:theme()/dashboard/cadastros/servicos-ativos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios-uuid",
    path: "/:theme()/dashboard/cadastros/usuarios/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios",
    path: "/:theme()/dashboard/cadastros/usuarios",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios-novo",
    path: "/:theme()/dashboard/cadastros/usuarios/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/novo/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-base-envios-contatos",
    path: "/:theme()/dashboard/campanhas/base-envios/contatos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/base-envios/contatos/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-base-envios",
    path: "/:theme()/dashboard/campanhas/base-envios",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/base-envios/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-base-envios-novo",
    path: "/:theme()/dashboard/campanhas/base-envios/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/base-envios/novo/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-campanha-individual",
    path: "/:theme()/dashboard/campanhas/campanha-individual",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/campanha-individual/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-CardView",
    path: "/:theme()/dashboard/campanhas/CardView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/CardView.vue")
  },
  {
    name: "theme-dashboard-campanhas-criar-template",
    path: "/:theme()/dashboard/campanhas/criar-template",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-criar-template-template-email",
    path: "/:theme()/dashboard/campanhas/criar-template/template-email",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/template-email/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-criar-template-template-sms",
    path: "/:theme()/dashboard/campanhas/criar-template/template-sms",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/template-sms/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-criar-template-template-whatsapp",
    path: "/:theme()/dashboard/campanhas/criar-template/template-whatsapp",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/criar-template/template-whatsapp/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-detalhes-da-campanha-id",
    path: "/:theme()/dashboard/campanhas/detalhes-da-campanha/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/detalhes-da-campanha/[id]/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-enviar-campanha",
    path: "/:theme()/dashboard/campanhas/enviar-campanha",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/enviar-campanha/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-Filter",
    path: "/:theme()/dashboard/campanhas/Filter",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/Filter.vue")
  },
  {
    name: "theme-dashboard-campanhas-historico-envios",
    path: "/:theme()/dashboard/campanhas/historico-envios",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/historico-envios/index.vue")
  },
  {
    name: "theme-dashboard-campanhas",
    path: "/:theme()/dashboard/campanhas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-ListView",
    path: "/:theme()/dashboard/campanhas/ListView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/ListView.vue")
  },
  {
    name: "theme-dashboard-campanhas-meus-compartilhamentos",
    path: "/:theme()/dashboard/campanhas/meus-compartilhamentos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/meus-compartilhamentos/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-ajuda-login",
    path: "/:theme()/dashboard/central-de-ajuda/ajuda-login",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/ajuda-login/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-faq",
    path: "/:theme()/dashboard/central-de-ajuda/faq",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/faq/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda",
    path: "/:theme()/dashboard/central-de-ajuda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-pagamentos",
    path: "/:theme()/dashboard/central-de-ajuda/pagamentos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/pagamentos/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-tutorial-uso-vemqda",
    path: "/:theme()/dashboard/central-de-ajuda/tutorial-uso-vemqda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/tutorial-uso-vemqda/index.vue")
  },
  {
    name: "theme-dashboard-chat",
    path: "/:theme()/dashboard/chat",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/chat/index.vue")
  },
  {
    name: "theme-dashboard-consultar-produtos",
    path: "/:theme()/dashboard/consultar-produtos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/consultar-produtos/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko-auditoria-de-venda",
    path: "/:theme()/dashboard/gestao-bko/auditoria-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/auditoria-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko",
    path: "/:theme()/dashboard/gestao-bko",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko-seleciona-provedor",
    path: "/:theme()/dashboard/gestao-bko/seleciona-provedor",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/seleciona-provedor.vue")
  },
  {
    name: "theme-dashboard-historico-de-vendas",
    path: "/:theme()/dashboard/historico-de-vendas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/historico-de-vendas/index.vue")
  },
  {
    name: "theme-dashboard",
    path: "/:theme()/dashboard",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/index.vue")
  },
  {
    name: "theme-dashboard-leads-disparo",
    path: "/:theme()/dashboard/leads-disparo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/leads-disparo/index.vue")
  },
  {
    name: "theme-dashboard-leads",
    path: "/:theme()/dashboard/leads",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/leads/index.vue")
  },
  {
    name: "theme-dashboard-meu-extrato",
    path: "/:theme()/dashboard/meu-extrato",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/meu-extrato/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-CardView",
    path: "/:theme()/dashboard/ofertas/CardView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/CardView.vue")
  },
  {
    name: "theme-dashboard-ofertas-detalhes-da-oferta-id",
    path: "/:theme()/dashboard/ofertas/detalhes-da-oferta/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/detalhes-da-oferta/[id]/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-Filter",
    path: "/:theme()/dashboard/ofertas/Filter",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/Filter.vue")
  },
  {
    name: "theme-dashboard-ofertas",
    path: "/:theme()/dashboard/ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-ListView",
    path: "/:theme()/dashboard/ofertas/ListView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/ListView.vue")
  },
  {
    name: "theme-dashboard-ofertas-meus-compartilhamentos",
    path: "/:theme()/dashboard/ofertas/meus-compartilhamentos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/meus-compartilhamentos/index.vue")
  },
  {
    name: "theme-dashboard-planos",
    path: "/:theme()/dashboard/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/planos/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-extrato-de-comissionamento",
    path: "/:theme()/dashboard/relatorios/extrato-de-comissionamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/extrato-de-comissionamento/index.vue")
  },
  {
    name: "theme-dashboard-relatorios",
    path: "/:theme()/dashboard/relatorios",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-contingencia",
    path: "/:theme()/dashboard/relatorios/relatorio-de-contingencia",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-contingencia/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-credito",
    path: "/:theme()/dashboard/relatorios/relatorio-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-historico-de-status-substatus",
    path: "/:theme()/dashboard/relatorios/relatorio-de-historico-de-status-substatus",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-historico-de-status-substatus/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-mailings-de-ofertas",
    path: "/:theme()/dashboard/relatorios/relatorio-de-mailings-de-ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-mailings-de-ofertas/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-ultimo-acesso",
    path: "/:theme()/dashboard/relatorios/relatorio-de-ultimo-acesso",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-ultimo-acesso/index.vue")
  },
  {
    name: "theme-dashboard-suporte",
    path: "/:theme()/dashboard/suporte",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/suporte/index.vue")
  },
  {
    name: "theme-dashboard-usuario-edicao-de-banco",
    path: "/:theme()/dashboard/usuario/edicao-de-banco",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/usuario/edicao-de-banco.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-aguardando-confirmacao-cliente",
    path: "/:theme()/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-consulta",
    path: "/:theme()/dashboard/venda/alugacell/v1/consulta",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/consulta.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/alugacell/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/dados-do-cliente.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-informacoes-do-plano",
    path: "/:theme()/dashboard/venda/alugacell/v1/informacoes-do-plano",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/informacoes-do-plano.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-retirada-confirmada",
    path: "/:theme()/dashboard/venda/alugacell/v1/retirada-confirmada",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/retirada-confirmada.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/aparelhos/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/aparelhos/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/aparelhos/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/aparelhos/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-planos",
    path: "/:theme()/dashboard/venda/aparelhos/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-edicao-de-venda-id",
    path: "/:theme()/dashboard/venda/edicao-de-venda/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/edicao-de-venda/[id]/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-da-categoria",
    path: "/:theme()/dashboard/venda/escolha-da-categoria",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-categoria/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-da-operadora",
    path: "/:theme()/dashboard/venda/escolha-da-operadora",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-operadora/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-do-tipo-de-venda",
    path: "/:theme()/dashboard/venda/escolha-do-tipo-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-do-tipo-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-planos",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/seguros/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/seguros/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/seguros/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/seguros/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/seguros/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-planos",
    path: "/:theme()/dashboard/venda/seguros/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-verificacao-usuario",
    path: "/:theme()/dashboard/venda/seguros/v1/verificacao-usuario",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/verificacao-usuario/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-analise-de-cobertura",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-planos",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/terra-movel/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/terra-movel/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/terra-movel/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-planos",
    path: "/:theme()/dashboard/venda/terra-movel/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/planos/index.vue")
  },
  {
    name: "theme-faq",
    path: "/:theme()/faq",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/faq/index.vue")
  },
  {
    name: "theme",
    path: "/:theme()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/index.vue")
  },
  {
    name: "theme-login",
    path: "/:theme()/login",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/login/index.vue")
  },
  {
    name: "theme-recuperacao-de-senha",
    path: "/:theme()/recuperacao-de-senha",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/recuperacao-de-senha/index.vue")
  },
  {
    name: "alugacell-aceite-de-venda",
    path: "/alugacell/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/alugacell/aceite-de-venda.vue")
  },
  {
    name: "dispositivos-aceite-de-venda",
    path: "/dispositivos/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/aceite-de-venda/index.vue")
  },
  {
    name: "dispositivos-venda-aceita",
    path: "/dispositivos/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/venda-aceita/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/index.vue")
  },
  {
    name: "r-user-offer-pdv",
    path: "/r/:user()/:offer()/:pdv()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/[pdv]/index.vue")
  },
  {
    name: "r-user-offer",
    path: "/r/:user()/:offer()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/index.vue")
  },
  {
    name: "seguros-residencial-aceite-de-venda",
    path: "/seguros-residencial/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/aceite-de-venda/index.vue")
  },
  {
    name: "seguros-residencial-venda-aceita",
    path: "/seguros-residencial/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/venda-aceita/index.vue")
  },
  {
    name: "seguros-aceite-de-venda",
    path: "/seguros/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/aceite-de-venda/index.vue")
  },
  {
    name: "seguros-venda-aceita",
    path: "/seguros/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/venda-aceita/index.vue")
  },
  {
    name: "t-promoter-offer-pdv",
    path: "/t/:promoter()/:offer()/:pdv()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/[pdv]/index.vue")
  },
  {
    name: "t-promoter-offer",
    path: "/t/:promoter()/:offer()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/index.vue")
  },
  {
    name: "terra-agendamento",
    path: "/terra/agendamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/agendamento/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-de-venda",
    path: "/terra/internet-fixa/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-de-venda/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-finalizado",
    path: "/terra/internet-fixa/aceite-finalizado",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-finalizado/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-recusado",
    path: "/terra/internet-fixa/aceite-recusado",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-recusado/index.vue")
  },
  {
    name: "terra-internet-fixa-venda-aceita",
    path: "/terra/internet-fixa/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/venda-aceita/index.vue")
  }
]