import payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_etWiZeV9erPSEbK6lFeStAjoND2Q9A6V1bSaP7XGQBs from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OsaRpEAH1V7jGkB2UWJmkE4_so7z4lbr3k2yYznCKxA from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_u_r_VrdhbRiGM8LiiGcyW52DTNJ_I_DVMCUOwqWq_SQ from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_62g9KgziqOeIig7Xdt_85CpuUoUmgJwKoK62SjrRVJw from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_J6lm6R03l_GGU_sFZFE5_A4M4B1xLALiYa_XZVETcoM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_twyOJ0_FfAZYLp8I8O9qkqeiugkbwnbrCqTxdqIh9YI from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_z4T6xQITNud8AuWpCGldzeLwQoCABA5a2QE2T9X3QKE from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/.nuxt/components.plugin.mjs";
import prefetch_client_j534vre_R_eGwYJQ_RI5BWaiMDHOj1fRGh7PQnvXW2U from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__ior_d3zfyitcd5v5ulh6nhcqfelwji/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_fGPK_hZY8x8kUYtefebxrDq6xVFE0ibs7vGUCK8vPlM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.2_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.1_@typ_5zj6qi2ysvo4k6pn57ts7nskky/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_dluwCGL7QwwLfIht0MrUmB0AoReaKEDVjpo2ff6P3qY from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.2_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.1_@typ_5zj6qi2ysvo4k6pn57ts7nskky/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_FHGTLlD8jq8JUQxmLsn_s8j9TGcObWQsJTB6s8Asc6k from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.2_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.1_@typ_5zj6qi2ysvo4k6pn57ts7nskky/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_qW4giAIi_Bf8HvVTZ8G6OAxs2nUY2tzXxWNhQK00ggA from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.1_@types+node@22.10.1_jiti@2.4.2_terser@5.39.0_yaml_lfvipaoyub4sonnaescehyv22q/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_YaaOZbiz1LQ1ALJhps27ZIIJQwSfjRlYZHn_VFPprjQ from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_rvfufdr3so3ha2yczarcrgsmke/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import Vue3Lottie_client_C2BfEOHhEU9SgcVpf7h9WmudM7vP7Wfy8PuC2qBPRrM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/Vue3Lottie.client.ts";
import maska_lyPpjSjnoA3nTYBiva4Tzs90JPX2LI8m_EQh5E7FRc8 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/maska.ts";
import vue_query_lBpTSoI5fm7Rf3JtpLcytc4Nol8xsMV0JViptdSe4qg from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/vue-query.ts";
export default [
  payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4,
  revive_payload_client_etWiZeV9erPSEbK6lFeStAjoND2Q9A6V1bSaP7XGQBs,
  unhead_OsaRpEAH1V7jGkB2UWJmkE4_so7z4lbr3k2yYznCKxA,
  router_u_r_VrdhbRiGM8LiiGcyW52DTNJ_I_DVMCUOwqWq_SQ,
  payload_client_62g9KgziqOeIig7Xdt_85CpuUoUmgJwKoK62SjrRVJw,
  navigation_repaint_client_J6lm6R03l_GGU_sFZFE5_A4M4B1xLALiYa_XZVETcoM,
  check_outdated_build_client_twyOJ0_FfAZYLp8I8O9qkqeiugkbwnbrCqTxdqIh9YI,
  chunk_reload_client_z4T6xQITNud8AuWpCGldzeLwQoCABA5a2QE2T9X3QKE,
  plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_j534vre_R_eGwYJQ_RI5BWaiMDHOj1fRGh7PQnvXW2U,
  slideovers_fGPK_hZY8x8kUYtefebxrDq6xVFE0ibs7vGUCK8vPlM,
  modals_dluwCGL7QwwLfIht0MrUmB0AoReaKEDVjpo2ff6P3qY,
  colors_FHGTLlD8jq8JUQxmLsn_s8j9TGcObWQsJTB6s8Asc6k,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_qW4giAIi_Bf8HvVTZ8G6OAxs2nUY2tzXxWNhQK00ggA,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  plugin_YaaOZbiz1LQ1ALJhps27ZIIJQwSfjRlYZHn_VFPprjQ,
  Vue3Lottie_client_C2BfEOHhEU9SgcVpf7h9WmudM7vP7Wfy8PuC2qBPRrM,
  maska_lyPpjSjnoA3nTYBiva4Tzs90JPX2LI8m_EQh5E7FRc8,
  vue_query_lBpTSoI5fm7Rf3JtpLcytc4Nol8xsMV0JViptdSe4qg
]